import React, { useEffect, useRef, useState } from "react";
import Editor, { DiffEditor, useMonaco, loader } from "@monaco-editor/react";
import { TabView, TabPanel } from "primereact/tabview";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import ReactMarkdown from "react-markdown";
import beautifyHtml from "js-beautify";
import Highlight from 'react-highlight'
import "highlight.js/styles/atom-one-dark.css";
import "primereact/resources/themes/saga-blue/theme.css"; // Theme
import "primereact/resources/primereact.min.css"; // Core CSS
import "primeicons/primeicons.css"; // Icons
import "primeflex/primeflex.css"; // CSS Utilities
import "monaco-themes/themes/Monokai Bright.json";

function App() {

  const monaco = useMonaco();

  // Editor references
  const htmlEditorRef = useRef<any>(null);
  const jsEditorRef = useRef<any>(null);
  const cssEditorRef = useRef<any>(null);

  const [_htmlcode, setHtmlCode] = useState<string>("");
  const [_jscode, setJsCode] = useState<string>("");
  const [_csscode, setCssCode] = useState<string>("");

  // Output state
  const [outputSrcDoc, setOutputSrcDoc] = useState<string>("");
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const [isModal2Visible, setIsModal2Visible] = useState<boolean>(false);

  // State for initial code
  const [initialHtml, setInitialHtml] = useState<string>("");
  const [initialJs, setInitialJs] = useState<string>("");
  const [initialCss, setInitialCss] = useState<string>("");
  const [aiFeedback, setAiFeedback] = useState<string>("");

  // State for CDN URLs
  const [cdnUrls, setCdnUrls] = useState<string[]>([
    "https://cdn.jsdelivr.net/npm/phaser@3.55.2/dist/phaser.min.js",
    "https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css",
    "https://code.jquery.com/jquery-3.5.1.slim.min.js",
  ]);

  // bool to check if screen width is less than 700
  const [_isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 700);

  // Editor mount handler
  const handleEditorDidMount = (editor: any, monaco: any, language: string) => {
    if (language === "html") htmlEditorRef.current = editor;
    if (language === "javascript") jsEditorRef.current = editor;
    if (language === "css") cssEditorRef.current = editor;
  };

  // Run code handler
  const runCode = () => {
    const htmlCode = htmlEditorRef.current.getValue();
    const jsCode = jsEditorRef.current.getValue();
    const cssCode = cssEditorRef.current.getValue();

    // Generate CDN links
    const {cssLinks, jsLinks} = generateCdnLinks(cdnUrls);

    const srcDoc = `
      <!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        ${cssLinks.join("\n")}
        <style>${cssCode}</style>
      </head>
      <body>
        ${htmlCode}
        ${jsLinks.join("\n")}
        <script type="text/javascript">${jsCode}</script>
        
      </body>
      </html>
    `;


    setOutputSrcDoc(srcDoc);

    // Save editor contents and cdnUrls to localStorage
    localStorage.setItem(
      "editorContents",
      JSON.stringify({
        html: htmlCode,
        js: jsCode,
        css: cssCode,
        cdnUrls: cdnUrls,
      })
    );


    // Check screen width to show modal
    if (window.innerWidth < 700) {
      setIsModalVisible(true);
    }
  };

  // Reset editors handler
  const resetEditors = () => {
    if (htmlEditorRef.current) htmlEditorRef.current.setValue("");
    if (jsEditorRef.current) jsEditorRef.current.setValue("");
    if (cssEditorRef.current) cssEditorRef.current.setValue("");
    setCdnUrls([]);
    setOutputSrcDoc("");
    localStorage.removeItem("editorContents");
  };

  const sendToAi = async () => {

    const htmlCode = htmlEditorRef.current.getValue();
    const jsCode = jsEditorRef.current.getValue();
    const cssCode = cssEditorRef.current.getValue();


    const {cssLinks, jsLinks} = generateCdnLinks(cdnUrls);


    const _srcDoc = `
      <!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        ${cssLinks.join("\n")}
        <style>
          ${cssCode}
        </style>
      </head>
      <body>
          ${htmlCode}
          ${jsLinks.join("\n")}
        <script type="text/javascript">
          ${jsCode}
        </script>
        
      </body>
      </html>
    `;



    const beautifiedCode = beautifyHtml.html(_srcDoc, {
      indent_size: 3,
      max_preserve_newlines: 1,
      preserve_newlines: true
    });
    setHtmlCode(beautifiedCode)


    const srcDoc = `<!DOCTYPE html><html lang="en"><head><meta charset="UTF-8"><meta http-equiv="X-UA-Compatible" content="IE=edge"><meta name="viewport" content="width=device-width, initial-scale=1.0">${cssLinks}<style>${cssCode}</style></head><body>${htmlCode}${jsLinks}<script type="text/javascript">${jsCode}</script></body></html>`;

    // Create messages array
    const messages = [
      {
        role: "system",
        content:
          "You are an advanced AI that gives feedback on HTML, JS, and CSS code for high school students learning to code. Just comment and give suggestions on the code do not rewrite the entire code. Keep you reviews as simple as possible.",
      },
      {
        role: "user",
        content: `${srcDoc}`,

      },
    ];

    setIsModal2Visible(true);
    setAiFeedback(""); // Clear previous feedback
    // if local server is running, use the following url http://loalhost:1234/chat/completions

    const url =
      window.location.origin == "http://localhost:3000"
        ? "http://localhost:1234/v1/chat/completions"
        : "/api/ai";

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ messages, 
          stream: true
        }),
      });

      const reader = response.body?.getReader();
      const decoder = new TextDecoder();
      let done = false;
      let streamData_ = "";

      while (!done) {
        if (url != "/api/ai") {
          const { value, done: readerDone } = (await reader?.read()) || {};
          done = readerDone as any;
          const chunkValue = decoder.decode(value, { stream: !readerDone });
          const segments = chunkValue.split("data: ").filter(segment => segment.trim() !== "");
          for (const segment of segments) {
            try {
              if(segment == "[DONE]"){
                done = true;
                break;
              }
              const chunk = JSON.parse(segment);
              streamData_ += chunk.choices[0].delta.content;
              if (chunk.choices[0].delta.content !== undefined) {
                setAiFeedback(streamData_);
              }
            } catch (error) {
              console.error("Failed to parse segment:", segment, error);
            }
          }
          
        } else {
          const { done: readerDone, value } = (await reader?.read()) || {};
    done = readerDone as any;
    const chunkValue = decoder.decode(value, { stream: !readerDone });

    // Split the chunkValue into multiple segments if they exist
    const segments = chunkValue.split("data: ").filter(segment => segment.trim() !== "");

    for (const segment of segments) {
      try {
        const chunk = JSON.parse(segment);
        streamData_ += chunk.response;

        if (chunk.response !== undefined) {
          setAiFeedback(streamData_);
        }
      } catch (error) {
        console.error("Failed to parse segment:", segment, error);
      }
    }
        }
      }
    } catch (error) {
      setAiFeedback("An error occurred while fetching with the AI.");
    }
  };

  // Generate CDN links function
  const generateCdnLinks = (urls: string[]) => {
    // Create two arrays to hold the CSS and JS links
    const cssLinks: string[] = [];
    const jsLinks: string[] = [];
  
    // Iterate through the URLs, filter and process them into the respective arrays
    urls
      .map((url) => url.trim())
      .filter((url) => url)
      .forEach((url) => {
        if (url.endsWith(".js")) {
          jsLinks.push(`<script src="${url}"></script>`);
        } else if (url.endsWith(".css")) {
          cssLinks.push(`<link rel="stylesheet" href="${url}" />`);
        }
      });
  
    // Return an object containing both arrays
    return {
      cssLinks,
      jsLinks,
    };
  };
  

  // Load saved data from localStorage on mount
  useEffect(() => {
    const savedData = localStorage.getItem("editorContents");
    if (savedData) {
      const { html, js, css, cdnUrls: savedCdnUrls } = JSON.parse(savedData);
      if (html) setInitialHtml(html);
      if (js) setInitialJs(js);
      if (css) setInitialCss(css);
      if (savedCdnUrls) setCdnUrls(savedCdnUrls);
    }
  }, []);


  useEffect(() => {
    if (monaco) {
      console.log("here is the monaco isntance:", monaco);
      import('monaco-themes/themes/Monokai Bright.json')
        .then(data => {
          monaco.editor.defineTheme('monokai-bright', data as any);
        })
        .then(_ => monaco.editor.setTheme('monokai-bright'))
    }
  }, [monaco]);

  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 700);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        width: "100vw",
        padding: "20px",
      }}
    >
      {/* Left Panel */}
      <div
        style={{
          width: `${_isMobile ? "100vw" : "50%"}`,
          height: "90%",
          display: "flex",
          flexDirection: "column",
          padding: "20px",
        }}
      >
        <TabView renderActiveOnly={false} style={{ flexGrow: 1 }}>
          <TabPanel header="HTML">
            <Editor
              height="70vh"
              defaultLanguage="html"
              defaultValue={initialHtml}
              onMount={(editor, monaco) =>
                handleEditorDidMount(editor, monaco, "html")
              }
              options={{ automaticLayout: true }}
            />
          </TabPanel>
          <TabPanel header="JavaScript">
            <Editor
              height="70vh"
              defaultLanguage="javascript"
              defaultValue={initialJs}
              onMount={(editor, monaco) =>
                handleEditorDidMount(editor, monaco, "javascript")
              }
              options={{ automaticLayout: true }}
            />
          </TabPanel>
          <TabPanel header="CSS">
            <Editor
              height="70vh"
              defaultLanguage="css"
              defaultValue={initialCss}
              onMount={(editor, monaco) =>
                handleEditorDidMount(editor, monaco, "css")
              }
              options={{ automaticLayout: true }}
            />
          </TabPanel>
          <TabPanel header="Libraries">
            <div style={{ padding: "10px" }}>
              <h3>Add CDN URLs</h3>
              <textarea
                value={cdnUrls.join("\n")}
                onChange={(e) => setCdnUrls(e.target.value.split("\n"))}
                style={{ width: "100%", height: "200px" }}
                placeholder="Enter one URL per line"
              />
            </div>
          </TabPanel>
        </TabView>
        <div style={{ marginTop: "10px", textAlign: "center" }}>
          <Button
            label="Send to AI"
            icon="pi pi-microchip-ai"
            className="p-button-info"
            onClick={sendToAi}
          />

          <Button
            label="Run"
            icon="pi pi-play"
            onClick={runCode}
            className="p-button-success"
            style={{ marginLeft: "10px" }}
          />
          <Button
            label="Reset"
            icon="pi pi-refresh"
            onClick={resetEditors}
            className="p-button-warning"
            style={{ marginLeft: "10px" }}
          />
        </div>
      </div>

      {/* Right Panel */}
      <div
        style={{
          width: "50%",
          height: "100%",
          display: window.innerWidth >= 700 ? "block" : "none",
        }}
      >
        <iframe
          srcDoc={outputSrcDoc}
          title="Output"
          sandbox="allow-scripts allow-modals"
          frameBorder="0"
          width="100%"
          height="100%"
          style={{
            padding: "20px",
            border: "1px solid black",
            margin: "auto",
            textAlign: "center",
            borderRadius: '10px'
          }}
        />
      </div>

      {/* Modal for small screens */}
      <Dialog
        header="Output"
        visible={isModalVisible}
        style={{ width: "100vw", height: "100vh" }}
        onHide={() => setIsModalVisible(false)}
      >
        <iframe
          srcDoc={outputSrcDoc}
          title="Output"
          sandbox="allow-scripts"
          allow-modals="true"
          frameBorder="0"
          width="100%"
          height="100%"
          style={{ padding: "20px", border: "1px solid black", borderRadius: '10px' }}
        />
      </Dialog>

      {/* Modal for AI Feedback */}
      <Dialog
        header="AI Code Review"
        visible={isModal2Visible}
        
        style={{ width: "100vw", height: "100vh", margin: 0 }}
        onHide={() => setIsModal2Visible(false)}
      >
       <div style={{display: 'flex', height: '100vh'}}>
          <div style={{border: '1px solid black', borderRadius: 10, width: '40%', height: '80vh',overflowY: 'scroll',  padding:  '40px', marginRight: '20px',zIndex:2}}>
            <ReactMarkdown children={aiFeedback} />
            </div>
            <div style={{padding: '25px',paddingRight: 40, width: '60%' ,height: '80vh',overflowY: 'scroll' ,backgroundColor :"#282C34", borderRadius: '10px', zIndex:2}}>
            <Highlight className='html' >
            {_htmlcode}
            </Highlight>
              </div>

        </div>
      </Dialog>
    </div>
  );
}

export default App;
